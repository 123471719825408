import * as React from "react"
import { Link } from "gatsby"
import TermsLayout from "../templates/terms-layout"

const Terms = ({ location }) => {
	return (
		<TermsLayout location={location}>
			<p>To support delivery of our Services Exploo may engage and use data processors with access to certain Customer Data (each, a <b>‘Subprocessor’</b>). This page provides important information about the identity of each Subprocessor. Terms used on this page but not defined have the meaning set forth in the <Link to="/terms">customer terms of service</Link> (or if applicable, the superseding written agreement between the Customer and Exploo or a Exploo affiliate(s)) (the <b>‘Agreement’</b>).</p>
			<h2>Third parties</h2>
			<p>Exploo currently uses third-party Subprocessors to provide infrastructure services, and to help us provide customer support and email notifications. Prior to engaging any third-party Subprocessor, Exploo performs diligence to evaluate their privacy, security and confidentiality practices, and executes an agreement implementing its applicable obligations.</p>
			<p>Infrastructure subprocessors</p>
			<p>Exploo may use the following Subprocessors to host Customer Data or provide other infrastructure that helps with delivery of our Services:</p>
			<ul>
				<li>Amazon Web Services, Inc.</li>
				<li>DigitalOcean</li>
				<li>SendGrid, Twilio</li>
				<li>MongoDB Inc.</li>
			</ul>
			<h2>Updates</h2>
			<p>As our business grows and evolves, the sub-processors that we engage may also change. We will endeavour to provide the owner of the Customer’s account with notice of any new sub-processors to the extent required under the Agreement, along with posting such updates here. Please check back frequently for updates.</p>
		</TermsLayout>
	)
}

export default Terms